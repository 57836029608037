.login-root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  background: url("../assets/squares.png") no-repeat center center fixed;
  background-size: cover;
}

.login-left {
  background-image: url("../assets/squares.png");
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #112427, #365f62);
  color: #bccbcf;
  text-align: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.login-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(17, 36, 39, 0.7),
    rgba(54, 95, 98, 0.7)
  );
  background-size: 400% 400%;
  z-index: -1;
  animation: gradientAnimation 15s ease infinite;
}

.login-card {
  z-index: 1;
}

.logo-container {
  background-color: rgba(188, 203, 207, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-in-out;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  margin: 7rem;
  backdrop-filter: blur(1px);
}

.logo-container:hover {
  background-color: rgba(188, 203, 207, 0.9);
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.logoimg {
  max-width: 100%;
  height: auto;
}

.social-media {
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: center;
  /*position: absolute;*/
  /*height:7.6vh;*/
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 1000;
}

.login-card {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* mobile */
@media (max-width: 767px) {
  .social-media {
    position: fixed;
  }

  .login-left {
    background-image: none;
  }
  .snackbar-container-mobile {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .snackbar-container-desktop {
    display: none;
  }

  .login-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-left {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .login-right {
    display: none;
  }

  .login-card-mobile {
    display: block;
    width: 100%;
    max-width: 400px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    text-align: center;
    color: black;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

/* desktop */
@media (min-width: 768px) {
  .snackbar-container-desktop {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: auto;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .snackbar-container-mobile {
    display: none;
  }

  .login-card-mobile {
    display: none;
  }

  .login-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .login-left {
    background-image: url("../assets/squares.png");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-right {
    display: flex;
  }

  .social-media {
    bottom: 20px;
    justify-content: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
